<template>
  <div class="tablezone">
    <div class="uptable-controls tablezone__controls">
      <Btn :small="true" class="tablesave btn btn_blue" @click="save">Сохранить таблицу</Btn>
      <Btn :small="true" class="tablesave btn btn_blue" @click="selectEnabled = !selectEnabled" v-if="selectedDates.length == 2">{{ selectEnabled ? 'Отменить сравнение' : 'Сравнить' }}</Btn>
    </div>
    <!--
    <table class="table" ref="table" >
    -->
    <div style="width: 100%; padding-top: 30px; margin-top: -30px;">
      <table class="table" ref="table" :data-cols-width="getColWidth">
        <thead>
          <tr>
            <td data-a-h="center" data-a-v="middle"><b>Итого:</b></td>
            <td class="datecell" data-a-h="center" data-a-v="middle" v-for="(worksheet, index) in filteredWorksheets" :key="worksheet.date" :data-fill-color="((index != (filteredWorksheets.length - 1)) && (filteredWorksheets[index + 1].data[mode] < worksheet.data[mode])) ? green : (((index != (filteredWorksheets.length - 1)) && (filteredWorksheets[index + 1].data[mode] > worksheet.data[mode])) ? red : null)" :class="{
              'good': (index != (filteredWorksheets.length - 1)) && (filteredWorksheets[index + 1].data[mode] < worksheet.data[mode]),
              'bad': (index != (filteredWorksheets.length - 1)) && filteredWorksheets[index + 1].data[mode] > worksheet.data[mode]
            }">
              <button class="datecell__delete" v-if="Role == 2" @click="deleteWorksheets(index)">&times;</button>
              <b>{{ worksheet.data[mode] }}</b>
            </td>
          </tr>
          <tr>
            <td data-a-h="center" data-a-v="middle"><b>CTR:</b></td>
            <td data-a-h="center" data-a-v="middle" v-for="(worksheet, index) in filteredWorksheets" :key="worksheet.date" :data-fill-color="((index != (filteredWorksheets.length - 1)) && (filteredWorksheets[index + 1].data.CTR < worksheet.data.CTR)) ? green : (((index != (filteredWorksheets.length - 1)) && (filteredWorksheets[index + 1].data.CTR > worksheet.data.CTR)) ? red : null)" :class="{
              'good': (index != (filteredWorksheets.length - 1)) && (filteredWorksheets[index + 1].data.CTR < worksheet.data.CTR),
              'bad': (index != (filteredWorksheets.length - 1)) && (filteredWorksheets[index + 1].data.CTR > worksheet.data.CTR)
            }"><b>{{ worksheet.data.CTR }}%</b></td>
          </tr>
          <tr>
            <td data-a-h="center" data-a-v="middle"><b>Процент от прошлого месяца:</b></td>
            <td data-a-h="center" data-a-v="middle" v-for="(worksheet, index) in filteredWorksheets" :key="worksheet.date" :data-fill-color="((index != (filteredWorksheets.length - 1)) && (percentages[index] > 100)) ? green : (((index != (filteredWorksheets.length - 1)) && (percentages[index] < 100)) ? red : null)" :class="{
              'good': (index != (filteredWorksheets.length - 1)) && (percentages[index] > 100),
              'bad': (index != (filteredWorksheets.length - 1)) && (percentages[index] < 100)
            }">
              <b v-if="index != (filteredWorksheets.length - 1)">{{ percentages[index] }}%</b>
            </td>
          </tr>
          <tr>
            <td data-a-h="center" data-a-v="middle"><b>Заполненность месяца данными:</b></td>
            <td data-a-h="center" data-a-v="middle" v-for="(worksheet) in filteredWorksheets" :key="worksheet.date">
              <b>{{ (typeof (worksheet.percentage) == 'number') ? (worksheet.percentage + '%') : '?' }}</b>
            </td>
          </tr>
          <tr>
            <td data-a-h="center" data-a-v="middle"><b>Дата:</b></td>
            <td data-a-h="center" data-a-v="middle" v-for="(worksheet, index) in filteredWorksheets" :key="worksheet.date" @click.exact="setSortDate(filteredDates[index])" @click.ctrl="selectEnabled ? null : selectDate(worksheetdates[index])" :class="{ 'selectedDate': selectEnabled || (selectedDates.includes(worksheetdates[index])) }">
              <b>{{ filteredDates[index] }}</b>
              <span class="sortarrow" :class="{ 'sortarrow_reverse': sortreverse }" v-if="sortdate && (sortdate == filteredDates[index])">↓</span>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr v-for="name in names" :key="name + ' - ' + project.name">
            <td class="link" data-a-v="middle">
              <div>{{ name }}</div>
            </td>
            <td v-for="(worksheet, index) in filteredWorksheets" :key="worksheet.date + ' - ' + project.name" :data-fill-color="((index != (filteredWorksheets.length - 1)) && ((filteredWorksheets[index + 1].data.table[name] ? filteredWorksheets[index + 1].data.table[name][worksheetMode] : 0) < (worksheet.data.table[name] ? worksheet.data.table[name][worksheetMode] : 0))) ? green : (((index != (filteredWorksheets.length - 1)) && ((filteredWorksheets[index + 1].data.table[name] ? filteredWorksheets[index + 1].data.table[name][worksheetMode] : 0) > (worksheet.data.table[name] ? worksheet.data.table[name][worksheetMode] : 0))) ? red : null)" :class="{
              'good': (index != (filteredWorksheets.length - 1)) && ((filteredWorksheets[index + 1].data.table[name] ? filteredWorksheets[index + 1].data.table[name][worksheetMode] : 0) < (worksheet.data.table[name] ? worksheet.data.table[name][worksheetMode] : 0)),
              'bad': (index != (filteredWorksheets.length - 1)) && ((filteredWorksheets[index + 1].data.table[name] ? filteredWorksheets[index + 1].data.table[name][worksheetMode] : 0) > (worksheet.data.table[name] ? worksheet.data.table[name][worksheetMode] : 0))
            }" :data-a-h="'center'" data-a-v="middle" :data-t="'n'">
              {{ worksheet.data.table[name] ? worksheet.data.table[name][worksheetMode] : 0 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Btn from './buttons/Btn.vue';
import TableToExcel from "@linways/table-to-excel"

export default {
  data: () => ({
    green: 'FFC8FFC8',
    red: 'FFFFC8C8',
    selectedDates: [],
    selectEnabled: false,
    sortdate: false,
    sortreverse: false
  }),
  components: {
    Btn
  },
  computed: {
    worksheets() {
      let ws = this.$store.state.worksheets
      if (!this.exceptions)
        return ws

      function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      }
      const sanitizedExceptions = this.exceptions.map(el => el.trim()).filter(el => el).map(exception => escapeRegExp(exception))
      if (!sanitizedExceptions.length)
        return ws
      const exceptionsPattern = new RegExp(sanitizedExceptions.join('|'))

      let res = ws.map((el) => {

        let table = {}

        for (let key in el.data.table) {
          if (!exceptionsPattern.test(key)) {
            table[key] = el.data.table[key]
          } 
        }
        let data = { ...el.data, table: table }
        return { ...el, data }
      })
      console.log(res)
      return res
    },
    filteredWorksheets() {
      if (!this.selectEnabled || (this.selectedDates.length != 2))
        return this.worksheets
      let res = this.worksheets.filter((el, i) => this.indexRule(i))
      return res
    },
    selectedIndexes() {
      return this.selectedDates.map(el => this.worksheetdates.indexOf(el))
    },
    mode() {
      if (parseInt(this.worksheetMode))
        return 'clicks'
      else
        return 'shows'
    },
    ...mapState(['project', 'worksheetMode', 'Role']),
    ...mapGetters(['exceptions']),
    getColWidth() {
      let base = "40"
      let res = base + this.worksheets.map(() => ",15").join("")
      return res
    },
    percentages() {
      return this.worksheets.map((worksheet, index) => {
        if (index == this.worksheets.length - 1) return ''
        return Math.round(worksheet.data[this.mode] / this.worksheets[index + 1].data[this.mode] * 10000) / 100
      })
    },
    worksheetdates() {
      return this.worksheets.map(worksheet => {
        let date = worksheet.date
        if (date % 12 == 0)
          return '12.' + (date / 12 - 1)
        else
          return ('' + (date % 12)).padStart(2, 0) + '.' + (date - date % 12) / 12
      })
    },
    filteredDates() {
      return this.filteredWorksheets.map(worksheet => {
        let date = worksheet.date
        if (date % 12 == 0)
          return '12.' + (date / 12 - 1)
        else
          return ('' + (date % 12)).padStart(2, 0) + '.' + (date - date % 12) / 12
      })
    },
    names() {
      let res = {}, ar = this.worksheets
      let index = this.worksheetdates.indexOf(this.sortdate)
      let keyf
      for (let i = 0; i < ar.length; i++) {
        let f = ar[i]
        if (i == index)
          keyf = f
        if (index == -1) {
          for (let key in f.data.table)
            res[key] = (!res[key]) ? f.data.table[key] : f.data.table[key].map((el, i) => res[key][i] + el)
        } else {
          for (let key in f.data.table)
            res[key] = [0, 0]
        }
      }
      if (keyf) {
        let f = keyf
        for (let key in keyf.data.table)
          res[key] = f.data.table[key]
      }
      let mult = 1
      if (this.sortdate && this.sortreverse)
        mult = -1
      let arr = []
      for (let key in res) {
        if (!Array.isArray(res[key]))
          console.error(res[key])
        arr.push([key, res[key]])
      }
      arr.sort((a, b) => (b[1][this.worksheetMode] - a[1][this.worksheetMode]) * mult)

      return arr.map(el => el[0])
    },
  },
  methods: {
    setSortDate(date) {
      if (this.sortdate == date) {
        this.sortreverse = !this.sortreverse
      } else {
        this.sortdate = date
        this.sortreverse = false
      }
    },
    indexRule(index) {
      if (!this.selectEnabled || (this.selectedDates.length != 2)) return true
      return this.selectedIndexes.includes(index)
    },
    selectDate(date) {
      let l = this.selectedDates.length
      this.selectedDates = this.selectedDates.filter(el => el != date)
      if (this.selectedDates.length < l)
        return
      if (this.selectedDates.length == 2)
        this.selectedDates.pop()
      this.selectedDates.push(date)
    },
    save() {
      this.$nextTick(() => {
        try {
          TableToExcel.convert(this.$refs.table, {
            name: this.project.name + ".xlsx",
            sheet: {
              name: "Данные"
            }
          })
        } catch (err) {
          console.error(err)
        }
      })
    },
    async deleteWorksheets(index) {
      if (this.selectEnabled)
        return alert("Для удаления столбца необходимо выйти из сравнения")
      if (confirm("Удалить данные за " + this.worksheetdates[index] + ' ?'))
        this.$store.dispatch('deleteWorksheets', index)
    }
  },
  watch: {
    filteredDates: {
      handler(v) {
        if (this.sortdate && !v.includes(this.sortdate))
          this.sortdate = false
      },
      deep: true
    },
    worksheetdates: {
      handler(v) {
        for (let el of this.selectedDates)
          if (!v.includes(el)) {
            this.selectedDates = []
            this.selectEnabled = false
            return
          }
      },
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
$danger: #dc3545
$success: #198754
$primary: #0d6efd

.sortarrow
  line-height: 0
  color: red
  font-weight: 900
  margin-left: 4px
  font-size: 20px
  display: inline-block
  &_reverse
    transform: scale(1,-1) translateY(-3px)
.uptable-controls
  display: flex
  gap: 20px

.tablezone
  &__controls
      margin-bottom: 20px
.table  
  display: block
  max-width: 100%
  font-size: 14px
  margin-bottom: 30px
  .selectedDate
    border: 2px solid $primary !important
  thead
    position: sticky
    top: 0
    z-index: 4
    &::after
      content: ''
      background: white 
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      top: 0
      display: block
      z-index: -1
    td
      background: white
  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  td:first-child
    width: 300px
    min-width: 300px
    max-width: 300px
  tr
    font-weight: 500
    white-space: nowrap
  td.bad
    background: rgba(255,200,200) !important
  td.good
    background: rgb(200,255,200) !important
  td.active
    background: white !important
    position: relative
    padding-right: 15px
    &::after
      position: absolute
      content: '↑'
      right: 2px
      font-size: 18px
      top: 50%
      transform: translateY(-65%)
      transform-origin: center center
  td.filtered
    outline: 2px solid violet
    .datecell__count
      opacity: 1
      pointer-events: auto
  .inversed .active::after
    content: '↓'

  td
    padding: 3px 10px
    position: relative
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:not(:first-child)
      text-align: center
    &.group
      text-align: left
      width: 150px !important
      max-width: 150px !important
      min-width: 150px !important
      position: relative !important
    &.link

      z-index: 2
      max-width: 300px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      
      &:hover 
        overflow: visible
        padding: 0
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: rgba(255,255,255,.8)
.datecell
  position: relative
  &__delete
    width: 20px
    background: rgba(255,255,255,.05)
    border: none
    position: absolute
    pointer-events: none
    opacity: 0
    bottom: 100%
    line-height: 0
    right: -11px
    z-index: 100
    background: rgba(0,0,0,.5)
    width: 17px
    height: 17px
    border-radius: 9999px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    font-size: 16px
    font-weight: 700
    color: red
    padding: 3px
    pointer-events: none
    transition: opacity .3s ease-out
    cursor: pointer
    opacity: 0
  &:hover &__input, &:focus-within &__input
    pointer-events: auto
    opacity: 1
  &__count
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    text-align: center
    width: auto
    min-width: 30px
    padding: 2px 8px
    position: absolute
    left: 50%
    transform: translateX(-50%)
    bottom: calc(100% + 5px)
    border: none
    background: white
    border: 2px solid violet
  &__input
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    width: 100px
    position: absolute
    right: 100%
    top: 0
    bottom: 0
    border: none
    background: white
    border: 2px solid black
    &_broken
      border: 2px solid red !important
  &:hover &__delete 
    opacity: 1
    pointer-events: auto
  &:hover &__delete:hover
    background: black
</style>