<template>
  <div>
    <div class="panel">
      <ProjectSelector class="panel__gap"/>
      <ProjectBtn v-if="Role == 2" mode="create" />
      <ProjectBtn v-if="project && (Role == 2)" mode="edit" />
      <ProjectBtn v-if="project && (Role == 2)" mode="delete" />
      <LogoutBtn />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LogoutBtn from './buttons/logoutBtn.vue';
import ProjectBtn from './buttons/ProjectBtn.vue';
import ProjectSelector from './ProjectSelector.vue';
export default {
  computed: {
    ...mapState(["url", "Role", "project"]),
  },
  components: { LogoutBtn, ProjectSelector, ProjectBtn}
}
</script>

<style lang="sass">
.panel
  display: flex
  gap: 10px
  &__right
    margin-left: auto
  &__gap
    margin-left: 20px
  select
    margin-right: 10px
  button
    font-weight: 700
    width: 40px
    height: 40px
    max-height: 40px
    padding: 0
    display: flex
    justify-content: center
    align-items: center
    line-height: 0
</style>