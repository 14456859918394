<template>
  <Teleport to="body">
    <Transition name="fade" >
      <div class="addpopup" @click="$emit('update:modelValue', false)" v-show="modelValue">
        <div class="addpopup__content" @click.stop>
          <div style="margin-bottom: 5px">Доступы:</div>
          <div class="addpopup__checks">
            <label v-for="user in allusers" :key="user.log">
              <input type="checkbox" v-model="usermap[user.id]">
              <div>{{user.log}}</div>
            </label>
          </div>
          <Btn @click="edit">Изменить</Btn>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
  import { mapState } from 'vuex'
  import Btn from './buttons/Btn.vue'
  export default {
    data: ()=>({
      usermap: {}
    }),
    props: {
      modelValue: {type: Boolean, default: false},
    },
    components: { Btn },
    methods: {
      async recalcAll() {
        await this.$store.dispatch('updateAllUsers')
        await this.$store.dispatch('updateProjects')
        this.usermap = {}
        for (let user of this.allusers)
          this.usermap[user.id] = this.project.accesslist.includes(user.id)
      },
      async edit(){
        this.$store.commit('toggleLoad','editProject')
        const name = this.project.name
        let accesslist = []
        
        for (let user in this.usermap) 
          if (this.usermap[user]) 
            accesslist.push(user)
        await fetch(this.url + 'project', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name, accesslist })
        }).then(async res=>{
          if (!res.ok) throw(await res.text())
          await this.recalcAll()
        }).catch(err=>{console.error(err); alert(err)})
        this.$store.commit('toggleLoad','editProject')
      }
    },
    computed: {
      ...mapState(['url','project','allusers'])
    },
    watch: {
      modelValue(v) {
        if (v)
          this.recalcAll()
      },
    }
  }
</script>

<style lang="sass">
.addpopup
  position: fixed
  z-index: 11
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  top: 0
  left: 0
  background: rgba(255,255,255,.6)
  &__content
    width: 550px
    max-width: 100%
    background: rgb(230, 230, 255)
    padding: 15px 20px
    border-radius: 20px
    box-shadow: 1px 1px 4px rgba(0,0,0,.3)
    overflow-y: auto
    >*:not(:last-child)
      margin-bottom: 15px
  &__input
    width: 100%
    padding: 5px 10px
    background: white
    border: 1px solid rgba(0,0,0,.15)
  &__checks
    max-height: 400px
    overflow-y: auto 
    label
      display: flex
      align-items: center
      font-size: 16px
      &:not:last-child
        margin-bottom: 8px
      input
        margin-right: 5px
</style>